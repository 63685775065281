<template>
  <Card>
    <template #content>
      <form class="block px-5" novalidate @submit.prevent="onSubmit">
        <div class="mb-4 relative">
          <label for="name" class="text-lg font-semibold mb-2 block">Name</label>
          <div class="p-input-icon-left w-full">
            <i class="pi pi-envelope" />
            <InputText
              id="name"
              type="text"
              v-model="$v.name.$model"
              :class="{ 'p-invalid': $v.name.$errors.length }"
              :disabled="isLoading"
              class="w-full" />
          </div>
          <p
            class="text-xs text-red-500 m-0 m-1 absolute"
            v-for="error in $v.name.$errors"
            :key="error.$uid">
            {{ error.$message }}
          </p>
        </div>
        <div class="mb-4 relative">
          <label for="email" class="text-lg font-semibold mb-2 block">Email</label>
          <div class="p-input-icon-left w-full">
            <i class="pi pi-envelope" />
            <InputText
              id="email"
              type="text"
              v-model="$v.email.$model"
              :class="{ 'p-invalid': $v.email.$errors.length }"
              :disabled="isLoading"
              class="w-full" />
          </div>
          <p
            class="text-xs text-red-500 m-0 m-1 absolute"
            v-for="error in $v.email.$errors"
            :key="error.$uid">
            {{ error.$message }}
          </p>
        </div>
        <div class="flex align-items-center justify-content-end">
          <Btn type="submit" label="Save" :loading="isLoading" />
        </div>
      </form>
    </template>
  </Card>
</template>

<script setup>
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Btn from "primevue/button";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/store";
import { reactive, ref } from "vue";

import { editProfileRequest } from "@/api";

const $toast = useToast();
const $userStore = useUserStore();

const rules = {
  name: { required, $lazy: true },
  email: { required, email, $lazy: true },
};

const form = reactive({
  name: $userStore.name,
  email: $userStore.email,
});

let isLoading = ref(false);

const $v = useVuelidate(rules, form);

const onSubmit = async () => {
  if (isLoading.value || ($userStore.name === form.name && $userStore.email === form.email)) {
    return;
  }
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }
  try {
    isLoading.value = true;
    await editProfileRequest(form);
    $userStore.SET_USER_DATA(form);
    $toast.add({
      severity: "info",
      summary: "Success",
      detail: "Profile data updated",
      life: 3000,
    });
  } catch (e) {
    if (e.response) {
      $toast.add({
        severity: "error",
        summary: "Error",
        detail: e.response.message || "Edit profile error",
        life: 5000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped></style>
