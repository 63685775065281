<template>
  <div class="profile-view">
    <h2 class="font-semibold">Edit Profile</h2>
    <div class="profile-section">
      <Divider class="mb-6" />
      <div class="grid">
        <div class="col-5">
          <h3 class="font-semibold text-xl uppercase mt-0 mb-2">User Information</h3>
          <p class="text-gray-600 text-sm m-0">Here is your main profile information</p>
        </div>
        <div class="col">
          <EditProfileForm />
        </div>
      </div>
    </div>
    <div class="profile-section">
      <Divider class="mb-6" />
      <div class="grid">
        <div class="col-5">
          <h3 class="font-semibold text-xl uppercase mt-0 mb-2">Password</h3>
          <p class="text-gray-600 text-sm m-0">Reset your current password</p>
        </div>
        <div class="col">
          <Btn
            class="p-button-danger"
            label="Send Password Reset Link"
            :loading="isLoading"
            @click="sendResetLink" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Divider from "primevue/divider";
import Btn from "primevue/button";

import EditProfileForm from "@/components/profile/EditProfileForm";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/store";
import { resetEmailPasswordRequest } from "@/api/authService";

const $toast = useToast();
const $userStore = useUserStore();

let isLoading = ref(false);

const sendResetLink = async () => {
  try {
    isLoading.value = true;
    const { success } = await resetEmailPasswordRequest({ email: $userStore.email });
    success &&
      $toast.add({
        severity: "success",
        summary: "Success",
        detail: `Reset password link was sent to your email: ${$userStore.email}`,
        life: 10000,
      });
  } catch (e) {
    console.log("send reset link error", e);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style lang="scss">
.profile-view {
  max-width: 860px;
  margin: 0 auto;
  .p-card-body {
    padding: 24px 48px;
    .p-card-content {
      padding: 0;
    }
  }
}
</style>
